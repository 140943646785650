
import Vue from 'vue';

type CommonType = {
  name: string;
  id: string;
  isGA4?: boolean;
  loaded?: boolean;
};

export default Vue.extend({
  components: {
    gaProfileView: () => import('./gaProfileViewNew.vue'),
    ga4ProfileView: () => import('./ga4ProfileViewNew.vue'),
  },
  props: ['account', 'selectedGA'],
  computed: {
    getSortedProperties(): CommonType[] | undefined {
      if (!this.account.loaded) return;
      if (!this.isSelected(this.account.id)) return this.account?.properties;
      let selectedProperty;
      let GAPropertyId;
      if (this.selectedGA?.viewId) {
        GAPropertyId = this.selectedGA?.propertyId;
      } else if (this.selectedGA?.propertyId) {
        GAPropertyId = this.selectedGA?.propertyId;
      }
      const sortedProperties = this.account?.properties?.filter((property: CommonType) => {
        if (property.id === GAPropertyId) {
          selectedProperty = property;
          return;
        } else return property;
      });
      if (selectedProperty) sortedProperties.unshift(selectedProperty);
      return sortedProperties;
    },
  },
  methods: {
    selectView(accountId: string, propertyId: string, viewId: string): void {
      this.$emit('select-view', accountId, propertyId, viewId);
    },
    async callLoadGAProfiles(accountId: string, propertyId: string, loaded: boolean): Promise<void> {
      this.$emit('loadGAProfiles', accountId, propertyId, loaded);
    },
    isSelected(accountId: string): boolean {
      if (this.selectedGA && this.selectedGA.accountId === accountId) {
        return this.selectedGA.accountId === accountId;
      } else return false;
    },
    isProfileChecked(propertyId: string): string {
      return this.checkProperty(propertyId) ? 'green--text' : 'grey--text text--lighten-2';
    },
    checkProperty(propertyId: string): boolean {
      if (this.selectedGA?.propertyId) {
        return this.selectedGA.propertyId === propertyId;
      } else return false;
    },
  },
});
